jQuery( document ).ready(function($) {
  // form
  $("#normani-fair-fan-alternate-video-upload-us-rca-45press").on("submit", function (e) {
    // Prevent default browser actions on form submission.
    e.preventDefault();
    // Get all the form user submitted data and get it ready to be sent.
    var data = $(this).serialize();
    // POST all the data to the SMF submission endpoint URL.
    $.ajax({
      type: "POST",
      url: "https://subs.sonymusicfans.com/submit",
      dataType: "json",
      data: data,
      xhrFields: {
        withCredentials: false
      },
      success: function (data) {
        $('body').addClass('thankyou');
        $('.form-popup').removeClass('active')
      },
      error: function (err) {
        $('.form-error').addClass('active');
      }
    });
  });

  $('.btn-submit').click(function(){
      $('.form-popup').addClass('active')
  })

  $('#terms').click(function(e){
    e.preventDefault()
    $('.disc-popup').addClass('active')
  })

  $('.agree').click(function(e){
    e.preventDefault()
    $('#terms').prop( "checked", true );
    $('.disc-popup').removeClass('active')
  })

  $('.dont-agree').click(function(e){
    e.preventDefault()
    $('#terms').prop( "checked", false );
    $('.disc-popup').removeClass('active')
  })
  
  $('.form-submit').click(function(e){
    if($('#field_email_address').val()) {
    $('#field_email_address').removeClass('error');
    } else {
      e.preventDefault()
      $('#field_email_address').addClass('error');
    }

    if($('#field_first_name').val()) {
    $('#field_first_name').removeClass('error');
    } else {
      e.preventDefault()
      $('#field_first_name').addClass('error');
    }

    if($('#field_last_name').val()) {
    $('#field_last_name').removeClass('error');
    } else {
      e.preventDefault()
      $('#field_last_name').addClass('error');
    }

    if($('#terms').is(":checked")) {
      $('.terms').removeClass('error');
    } else {
      e.preventDefault()
      $('.terms').addClass('error');
    }

    if($('#field_twitter').val()) {
      $('#field_twitter').removeClass('error');
    } else {
      e.preventDefault()
      $('#field_twitter').addClass('error');
    }
    
    if($('.instagram-field').val()) {
      $('.instagram-field').removeClass('error');
    } else {
      e.preventDefault()
      $('.instagram-field').addClass('error');
    }

    if($('.video-field').val()) {
      $('.video-field').removeClass('error');
    } else {
      e.preventDefault()
      $('.video-field').addClass('error');
    }
  })

  $('.close').click(function(){
    $('.form-popup').removeClass('active')
  })

});